import React, { useEffect, useState } from 'react'
import { Select, Alert } from 'antd';
import 'antd/dist/antd.min.css';
import { getCategorys, getProjects } from '../helpers/consumeCategory';
import CardProjects from './CardProjects';
const { Option, OptGroup } = Select;

function Portfolio() {

  const [categorys, setCategorys] = useState([]);
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (value) => {
    setIsLoading(true);
    getProjects("/portfolio?idCategory=" + value).then((data) => {
      setProjects(data.data);
      setIsLoading(false);
    })

  }

  useEffect(() => {
    getCategorys("/category").then((data) => {
      setCategorys(data.data);
    })
    getProjects("/portfolio").then((data) => {
      setProjects(data.data);
    })
    return () => { }; // ComponenteDidUmount
  }, []); //constructor

  useEffect(() => { }); /// componentDidUpdate

  const categoryElements = categorys.map((cat) => {
    const { id, name } = cat;
    return <Option key={id} value={id}>{name}</Option>
  })

  return (
    <section class="container-fluid pt-4" id="portfolio">
      <div class="section-heading text-center">
        <h2 class="text-light"><b>Portfolio</b></h2>
      </div>
      <div class="row justify-content-end">
        <div class="col-12 text-right">
          <Select defaultValue="all" style={{ width: 200 }} onChange={handleChange}>
            <OptGroup label="">
              <Option value="">All</Option>
              {categoryElements}
            </OptGroup>
          </Select>
        </div>
        <br />
        <div class="col-lg-3 col-md-4 col-sm-6 mb-4 mt-4">
          <Alert
            description="Click on the image to see more about the project."
            type="warning"
            showIcon
          />
        </div>
      </div>

      {isLoading ? (
        <div class="row justify-content-center">
          <div class="d-flex  m-5">
            <div class="spinner-grow text-light" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        <div class="row">
          <CardProjects project={projects} key={projects.id} />
        </div>
      )}
    </section>
  )
}

export default Portfolio