import React from 'react'

function Navbar() {
    return (
        <>
            <div class="navbar navbar-expand-lg navbar-dark sticky" data-offset="500">
                <div class="container">
                    <button class="navbar-toggler" data-toggle="collapse" data-target="#main-navbar" aria-expanded="true">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                            <path fillRule="evenodd"
                                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 
                            0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                        </svg>
                    </button>
                    <div class="collapse navbar-collapse " id="main-navbar">
                        <ul class="navbar-nav ml-auto" id="ul-navbar">
                            <li class="nav-item"
                            data-toggle="collapse" data-target=".navbar-collapse.show">
                                <a href="#home" class="nav-link">Home</a>
                            </li>
                            <li class="nav-item"
                            data-toggle="collapse" data-target=".navbar-collapse.show">
                                <a href="#about" class="nav-link">About Me</a>
                            </li>
                            <li class="nav-item"
                            data-toggle="collapse" data-target=".navbar-collapse.show">
                                <a href="#skills" class="nav-link">Skills</a>
                            </li>
                            <li class="nav-item"
                            data-toggle="collapse" data-target=".navbar-collapse.show">
                                <a href="#my-way" class="nav-link">My Way</a>
                            </li>
                            <li class="nav-item"
                            data-toggle="collapse" data-target=".navbar-collapse.show">
                                <a href="#my-certifications" class="nav-link">Certifications</a>
                            </li>
                            <li class="nav-item"
                            data-toggle="collapse" data-target=".navbar-collapse.show">
                                <a href="#portfolio" class="nav-link">Portfolio</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>


    )
}

export default Navbar