import React from 'react'

function Header() {
  return (
    <div class="caption-header text-center" data-aos="zoom-in-up"
    data-aos-delay="100">
      <h5 class="header-text"
        >
        Welcome
      </h5>
      <h1 class="header-text mb-4" data-aos="zoom-in-up"
      data-aos-delay="200">
        I am <b class="name">Brayan Mares Bueno</b>
      </h1>
      <div class="badge" data-aos="zoom-in-up" 
      data-aos-delay="300">
        Junior Software Developer
      </div>
    </div>
  )
}

export default Header