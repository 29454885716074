import InstanceCategorys from "../api/api-portfolio.js";

const getCategorys = async (path) => {
	return await InstanceCategorys.get(path).then((response) => response.data);
};

const getProjects = async (path) => {
	return await InstanceCategorys.get(path).then((response) => response.data);
};

export { getCategorys, getProjects };