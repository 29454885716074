import React from 'react'
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { Image } from 'antd';
import { isMobile } from 'react-device-detect';
import 'react-photo-view/dist/react-photo-view.css';
import 'bootstrap-icons/font/bootstrap-icons.css';


function CardProjects({ project }) {
    const baseURLImg = "https://my-portfolio-bmb.s3.us-east-2.amazonaws.com/";

    const listProjects = project.map((proj) => {
        const { imagePreview, image, id, repository, demo, name } = proj;
        return (
            <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 mb-2" align="center" key={id}
                data-aos="zoom-out">
                <div class="card" style={{ "width": "300px" }}>
                    {isMobile ?
                        <PhotoProvider
                            maskOpacity={0.5}>
                            <PhotoView src={baseURLImg + imagePreview} key={id}>
                                <img src={baseURLImg + image} alt={name} />
                            </PhotoView>
                        </PhotoProvider>
                        :
                        <Image
                            src={baseURLImg + image}

                            preview={{
                                src: baseURLImg + imagePreview,
                            }}
                        />
                    }
                    <div class="card-body">
                        <h5 class="card-title" style={{ "color": "#fff" }}>{name}</h5>
                        <p class="card-text" style={{ "color": "#84a98c" }}>Click the image</p>
                        {repository ?
                            <a href={repository} class="btn btn-dark mr-1">
                                <i class="bi bi-github"></i>
                            </a>
                            : <></>
                        }
                        {demo ?
                            <a href={demo} class="btn btn-dark">
                                <i class="bi bi-binoculars-fill"></i>
                            </a>
                            : <></>
                        }

                    </div>
                </div>
            </div>
        )
    })

    return (
        <>
            {listProjects}
        </>

    )
}

export default CardProjects