import './App.css';
import Navbar from './components/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'jquery/dist/jquery.min.js'
import 'bootstrap/dist/js/bootstrap.min.js'
import './assets/css/portfolio.css';
import Header from './components/Header';
import About from './components/About';
import Skills from './components/Skills';
import MyWay from './components/MyWay';
import Certifications from './components/Certifications';
import Portfolio from './components/Portfolio';
import Footer from './components/Footer';
import { useEffect } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";

function App() {

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <div class="vg-page page-home" id="home">
        <Navbar></Navbar>
        <Header></Header>
      </div>
      <About></About>
      <Skills></Skills>
      <MyWay></MyWay>
      <Certifications></Certifications>
      <Portfolio></Portfolio>
      <Footer></Footer>
    </>
  );
}

export default App;
