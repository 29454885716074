import React from 'react'

function Footer() {
    return (
        <div class="vg-footer">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <p class="text-center mb-0 mt-4">Copyright &copy;2022. All right reserved by Brayan Mares Bueno</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer