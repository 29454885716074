import React from 'react'

function Certifications() {
    return (
        <section class="container-fluid pt-4 pb-4" id='my-certifications'>
            <div class="section-heading text-center"
                data-aos="zoom-in">
                <h2 class="text-light"><b>Certifications & Courses</b></h2>
            </div>
            <div class="row"
                data-aos="zoom-in"
                data-aos-delay="300">
                <div class="col">
                    <iframe src="https://my-portfolio-bmb.s3.us-east-2.amazonaws.com/Certifications.pdf"
                        width="100%"
                        height="600px"
                        title='Certifications-bmb'
                        frameBorder="0"
                        type="application/pdf"
                        allowFullScreen>
                    </iframe>
                </div>
            </div>
        </section>
    )
}

export default Certifications