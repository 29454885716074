import React from 'react'

function MyWay() {
    return (
        <section class="container-fluid pt-5" id='my-way'>
            <div class="section-heading text-center"
                data-aos="zoom-out-up">
                <h2 class="text-light"><b>My Way</b></h2>
            </div>
            <div class="row">
                <div class="col-md-6 wow" data-wow-delay="200ms">
                    <h2 class="fw-normal text-light"
                        data-aos="fade-left">Experience</h2>
                    <ul class="timeline mt-4 pr-md-5">
                        <li
                            data-aos="fade-left"
                            data-aos-delay="100">
                            <div class="title" style={{ 'backgroundColor': '#52796f', color: '#fff' }}>august 2023 - actually</div>
                            <div class="details">
                                <h5>Developer Fullstack SpringBoot/React Junior</h5>
                                <small class="fg-theme">KJ Logistic</small>
                                <p>
                                    At KJ Logistics, I manage the entire lifecycle of requirements for the development and maintenance of administrative logistics software, ensuring the implementation of efficient and scalable solutions.<br />
                                    <br />
                                    In the BackEnd area, I use technologies such as SpringBoot, SpringSecurity, JPA, SpringBoot Web, DevTools, SQL, Lombok, JWT, and ModelMapper (WebToken, Impl, Jackson). I apply SOLID principles and the main design patterns in SpringBoot, working with a clean three-tier architecture (presentation, service, persistence) to create and maintain the corresponding API.<br />
                                    <br />
                                    In the FrontEnd domain, I employ tools like React, React Cookie, React Dom, React Redux Toolkit, React Router Dom, React Toastify, Bootswatch, and TanStack Query. I am responsible for structuring requests, designing interfaces, and ensuring responsiveness across various devices.<br />
                                    <br />
                                    Additionally, I use GitHub as a code repository and AWS for deploying our solutions, utilizing services such as S3, EC2, R5, and Elastic Beanstalk. With this combination of skills and tools, I bring value to the project and the team, ensuring agile and efficient development.
                                </p>
                            </div>
                        </li>
                        <li
                            data-aos="fade-left"
                            data-aos-delay="300">
                            <div class="title">october 2021 - june 2022</div>
                            <div class="details">
                                <h5>TRAINEE SOFTWARE DEVELOPER</h5>
                                <small class="fg-theme">E&Y</small>
                                <p>At E&Y, after the absorption of part of the CeCoaching staff, my position was adjusted according to my technical skills.
                                    <br />
                                    I was initially trained in Appian and later specialized in Java with Spring Boot. As a Java Developer Trainee, I contribute to the development and maintenance of requirements using Java SpringBoot (Web, REST and JPA), OOP, RestAPIs, and SQL database.
                                    <br />
                                    Work with microservices architecture and Git versioning management.
                                    My experience also includes the implementation of good development practices, the application of SOLID principles and the use of FeignClient for communication between microservices.</p>
                            </div>
                        </li>
                        <li
                            data-aos="fade-left"
                            data-aos-delay="400">
                            <div class="title">may - august 2021</div>
                            <div class="details">
                                <h5>Developer FullStack</h5>
                                <small class="fg-theme">SigNot</small>
                                <p>
                                    Full Stack Web Developer<br />
                                    Development and maintenance of website for management
                                    notarial.<br />
                                    Responsible for improving and troubleshooting the website
                                    using AngularJS, Bootstrap, CSS and Js.<br />
                                    Consumption and improvements in the Signot RESTAPI through SQL, NodeJS and
                                    SailsJS.<br />
                                    Database maintenance with SQL.<br />
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="col-md-6 wow">
                    <h2 class="fw-normal text-light"
                        data-aos="fade-right">Education</h2>
                    <ul class="timeline mt-4 pr-md-5">
                        <li
                            data-aos="fade-right"
                            data-aos-delay="100">
                            <div class="title">2021-2023</div>
                            <div class="details">
                                <h5>Software Development and Management Engineering</h5>
                                <small class="fg-theme">Universidad Tecnologica de Queretaro</small>
                            </div>
                        </li>
                        <li
                            data-aos="fade-right"
                            data-aos-delay="200">
                            <div class="title">2019-2021</div>
                            <div class="details">
                                <h5>Higher University Technician in Information Technology and
                                    Communication Multiplatform Software Development</h5>
                                <small class="fg-theme">Universidad Tecnologica de Guanajuato</small>
                            </div>
                        </li>
                    </ul>
                </div>

            </div>
        </section>
    )
}

export default MyWay
